import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="px-10 py-20 bg-background-accent">
      <div className="container md:flex md:flex-row">
        <div className="flex-1">
          <img className="w-[120px] h-[26.5px]" src="/images/logo-with-text-primary.png" alt="countfit" />
          <address className="mt-8 leading-[1.4] text-[0.8rem] not-italic [&>span]:block text-foreground-accent">
            <h6 className="font-bold">주식회사 룸</h6>
            <span>대표이사: 오승현</span>
            <span>개인정보 책임자: 오승현 / 연락처: 010-9186-3419</span>
            <span>사업자등록번호: 326-86-02734 법인등록번호: 131111-0677583</span>
            <span>주소: 경기도 수원시 영통구 광교로 156, 6층 2호(이의동, 광교비즈니스센터)</span>
            <br />
            <span>이메일: count-fit@naver.com</span>
            <br />
            <span>Copyright ⓒ 2024 wroom. All rights reserved</span>
          </address>
        </div>
        <div className="flex-1 md:block hidden" />
        <div className="md:mt-0 mt-12 flex-1 md:flex md:flex-col md:items-end">
          <div className="flex-1 flex w-full flex-row items-center">
            <a
              className="text-[0.8rem] font-bold"
              href="https://gregarious-manx-605.notion.site/ebc6c73115544acb940868f072f77472?pvs=4"
              target="_blank"
              rel="noreferrer"
            >
              사용자 이용약관
            </a>
            <span className="mx-2 w-[1px] h-[16px] bg-black block" />
            <Link className="text-[0.8rem] font-bold" to="/privacy-policy">
              개인정보 처리방침
            </Link>
          </div>
          <div className="md:mt-0 mt-8 flex-1 flex w-full flex-row gap-4">
            <div className="flex-1">
              <h6>사업제휴 및 문의</h6>
              <div className="mt-[20px] px-[20px] py-[10px] bg-white border inline-block border-foreground-accent rounded-full">
                <a href="mailto:count-fit@naver.com">문의메일 보내기</a>
              </div>
            </div>
            <div className="flex-1">
              <h6>SNS 계정</h6>
              <div className="mt-[20px] flex flex-row items-center gap-2">
                <div className="w-[48px] h-[48px]">
                  <img src="/icons/instagram-gray-icon.png" alt="INSTAGRAM" />
                </div>
                <div className="w-[48px] h-[48px]">
                  <img src="/icons/naver-gray-icon.png" alt="INSTAGRAM" />
                </div>
                <div className="w-[52px] h-[52px]">
                  <img src="/icons/kakao-gray-icon.png" alt="INSTAGRAM" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
