import React from 'react';

const MainVisual: React.FC = () => {
  return (
    <section
      className="min-h-dvh flex justify-center before:w-full before:h-full before:absolute before:top-0 before:left-0 before:bg-black/50"
      style={containerStyle}
    >
      <div className="flex-1 container lg:px-16 px-10 flex flex-col justify-center z-10">
        <h4 className="text-[2.25rem] text-white font-bold leading-[1.1]">
          PT 가르치기도 바쁜데 횟수 카운트하기,
          <br />
          운동일지 기록하기 어려우셨죠?
          <br />
          이제는 카운트핏으로 회원들의 운동을 자동으로 기록하세요
        </h4>
        <p className="mt-8 text-[1rem] text-white">
          소비자들이 PT 트레이너를 고르는 기준 중에 트레이너가 운동일지를 만들어주는지,
          <br className="lg:block hidden" />
          그리고 PT 없는날도 운동을 관리해 주는지 여부가 PT 트레이너를 선택하는 중요한 기준이라는 사실,
          <br className="lg:block hidden" />
          다들 아시나요? 이제 카운트핏으로 한차원 높은 서비스를 회원에게 제공해 보세요.
        </p>
        <div className="mt-8 flex gap-4 items-center">
          <a className="px-8 py-3 bg-white rounded-full" href="https://play.google.com/store/apps/details?id=ai.countfit2">
            <div className="w-32">
              <img className="w-full h-full" src="/images/store-google-play.png" alt="Google Play" />
            </div>
          </a>
          <a className="px-8 py-3 bg-white rounded-full" href="https://apps.apple.com/kr/app/%EC%B9%B4%EC%9A%B4%ED%8A%B8%ED%95%8F/id6670605428">
            <div className="w-32">
              <img className="w-full h-full" src="/images/store-app-store.png" alt="App Store" />
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

const containerStyle: React.CSSProperties = {
  backgroundImage: `url('/images/main-visual.jpg')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
};

export default MainVisual;
