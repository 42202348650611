import React from 'react';

const ServiceSuggestion: React.FC = () => {
  return (
    <section id="service-suggestion" className="h-[700px] text-white" style={suggensionSectionStyle}>
      <div className="container lg:px-16 px-10 py-20 w-full h-full flex flex-col justify-center">
        <h4 className="text-[2.8rem] leading-[1.2] font-extrabold">
          카운트핏의 서비스를
          <br />
          센터에 도입해보세요
        </h4>
        <p className="mt-4 text-[1.4rem]">
          회원들에게 한차원 높은 운동 프로그램과 관리서비스를 제공하고자 했던 센터 대표님들이 계시다면
          <br className="md:block hidden" />
          이제 카운트핏을 도입히고 PT 마케팅 및 모객의 주요전략으로 활용하세요
          <br className="md:block hidden" />
          기존 객단가 매출도 올리고 나아가 카운트핏을 제공하는 고급화전략으로 PT 세션당 객단가를 높여 보세요.
        </p>
      </div>
    </section>
  );
};

const suggensionSectionStyle: React.CSSProperties = {
  backgroundImage: `url('/images/meeting.png')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
};

export default ServiceSuggestion;
