import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { DefaultLayout } from './layouts';
import Index from './pages/Index';
import PrivacyPolicyPage from './pages/privacy-policy/PrivacyPolicyPage';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route path="/" element={<Index />} />
          </Route>

          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
