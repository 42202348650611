export interface CreateFitnessCenterBody {
  centerName: string;
  centerPhone: string;
  ceoName: string;
  businessNumber: string;
  email: string;
  postNumber: number;
  basicAddress: string;
  detailAddress: string;
  status?: number;
}


export enum FitnessCenterStatus {
  /** @description 승인 대기 */
  PENDING = 1,
  /** @description 승인 */
  APPROVED = 2,
  /** @description 폐업 */
  SHUTDOWN = 3,
  /** @description 휴업 */
  SUSPEND = 4,
}